<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("SOUS-TRAITANT") }}</h1>
      <b-button
        size="sm"
        variant="success"
        class="button-succes-style ml-2 float-right mb-2"
        @click="handleStore"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}
      </b-button>
    </div>

    <storeModalVue @updateList="setup(true)"></storeModalVue>

    <div class="content">
      <div class="content-header">
        <searchBar
          :searchText="$t('SOUSTRAITANT_SEARCH')"
          :searchFunc="allsousTraitant"
          :showFiltre="false"
          @returnValue="updateSearch($event)"
        ></searchBar>
      </div>
      <div class="filtre">
        <p class="table-count">
          {{ getTotalRowsousTraitant }} {{ $t("SOUS-TRAITANT") }}
        </p>
        <div class="productTrie" v-if="currententreprise.lib != null">
          {{ currententreprise.lib }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="selectentreprise()"
          />
        </div>
      </div>
      <div class="table-rapport-style w-100">
        <table>
          <thead>
            <tr>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("NAME") }}
              </th>
              <th>
                {{ $t("SIRET") }}
              </th>
              <!-- <th>
                {{ $t("RGE") }}
              </th> -->
              <!-- <th style="border-radius: 0px 5px 0px 0px">
                  {{ $t("FAVORITE") }}
                </th> -->
              <th class="action_head"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAllsousTraitant"
              :key="i"
              :class="{ default_element: data.is_default }"
            >
              <td aria-colindex="2" role="cell">
                {{ data.name }}
              </td>
              <td aria-colindex="3" role="cell">
                {{ data.num_siret }}
              </td>
              <!-- <td aria-colindex="4" role="cell">
                {{ data.num_rge }}
              </td> -->
              <td aria-colindex="5" role="cell" class="action_col">
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="danger"
                  @click.prevent.stop="handleDelete(data)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="success"
                  @click.prevent.stop="handleUpdate(data)"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-modal
        id="ModelConfigSousTraitant"
        ref="ModelConfigSousTraitant"
        hide-footer
        @hide="v$.$reset()"
      >
        <template #modal-header>
          <h5>{{ $t("EDIT") }} {{ $t("SOUS-TRAITANT") }}</h5>
          <b-button size="sm" @click="resetModal()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>
        <form v-if="sousTraitantToConfig">
          <div class="center">
            <b-form-group :label="$t('NAME') + '*'" label-for="name">
              <b-form-input
                id="name"
                v-model="v$.sousTraitantToConfig.name.$model"
                :state="validateState('name')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="name-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.sousTraitantToConfig.name.$errors"
                id="name-feedback"
              ></error-handle>
              <div v-if="erreurlist.name" class="error-message">
                <ul v-if="Array.isArray(erreurlist.name)">
                  <span v-for="(e, index) in erreurlist.name" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.name }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('SIRET') + '*'" label-for="num_siret">
              <b-form-input
                id="num_siret"
                v-model="v$.sousTraitantToConfig.num_siret.$model"
                :state="validateState('num_siret')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="num_siret-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.sousTraitantToConfig.num_siret.$errors"
                id="num_siret-feedback"
              ></error-handle>
              <div v-if="erreurlist.num_siret" class="error-message">
                <ul v-if="Array.isArray(erreurlist.num_siret)">
                  <span v-for="(e, index) in erreurlist.num_siret" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.num_siret }}</span>
              </div>
            </b-form-group>
          </div>

          <div class="actionModel">
            <b-alert variant="warning" show v-if="errorU" class="messageError">
              {{ errorU }}
            </b-alert>
            <b-button variant="success" @click="EditSousTraitant">
              <div class="block-spinner">
                {{ $t("EDIT") }}
                <div v-if="getLoadingsousTraitant" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </form>
      </b-modal>

      <deleteModalVue
        :loader="getLoadingsousTraitant"
        :elemToDelete="sousTraitantToDelete"
        :elemDelete="delete_sousTraitant"
        @updateList="setup(false)"
      ></deleteModalVue>

      <b-pagination
        v-if="search == ''"
        v-model="page"
        :total-rows="getTotalRowsousTraitant"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
      <b-pagination
        v-else
        v-model="page"
        :total-rows="getTotalRowsousTraitant"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getLoadingsousTraitant"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <CommentMarche to="/how-it-work/sousTraitant"></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
  alphaNum,
} from "@vuelidate/validators";
import deleteModalVue from "../components/ui/deleteModal.vue";
import storeModalVue from "../components/Ajoutmodel/sousTraitantmodal.vue";
import searchBar from "../components/ui/searchBar.vue";
import errorHandle from "../components/ui/errorHandle.vue";
import CommentMarche from "../components/commentMarche.vue";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    searchBar,
    deleteModalVue,
    storeModalVue,
    errorHandle,
    CommentMarche,
  },
  data() {
    return {
      search: "",
      options: [
        { value: "type.particulier", text: "Particulier" },
        { value: "type.professionnel", text: "Professionnel" },
      ],
      sousTraitantToConfig: null,
      sousTraitantToDelete: null,
      page: 1,
      perPage: 10,
      errorU: false,
      currententreprise: {
        id: null,
      },
      erreurlist: {
        name: null,
        num_siret: null,
        // num_rge: null,
      },
      filtre_entreprise: {
        id: null,
      },
    };
  },
  validations() {
    return {
      sousTraitantToConfig: {
        name: { required, maxLength: maxLength(100) },
        num_siret: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(14),
          alphaNum,
        },
        // num_rge: { required, maxLength: maxLength(100) },
      },
    };
  },
  methods: {
    ...mapActions([
      "allsousTraitant",
      "edit_sousTraitant",
      "delete_sousTraitant",
      "all_entreprises",
    ]),

    validateState(name) {
      const { $dirty, $error } = this.v$.sousTraitantToConfig[name];
      return $dirty ? !$error : null;
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },

    debounceInput2: _.debounce(function (e) {
      this.all_entreprises({
        search: e,
      });
    }, 500),

    selectentreprise(query) {
      if (query) {
        this.filtre_entreprise = query;
      } else {
        this.filtre_entreprise = {
          lib: null,
        };
      }
      this.setup(true);
    },

    async searchSousTraitant() {
      await this.allsousTraitant({
        search: this.search,
        page: this.page,
        per_page: this.perPage,
      });
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    handleUpdate(CAT) {
      this.sousTraitantToConfig = { ...CAT };
      this.$refs["ModelConfigSousTraitant"].show();
    },
    handleDelete(CAT) {
      this.sousTraitantToDelete = { ...CAT };
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },
    resetModal() {
      this.erreurlist = {
        name: null,
        num_siret: null,
        // num_rge: null,
      };
      this.errorU = null;
      this.hideModal("ModelConfigSousTraitant");
    },
    async EditSousTraitant() {
      const isFormCorrect = await this.v$.sousTraitantToConfig.$error;
      this.erreurlist = {
        name: null,
        num_siret: null,
        // num_rge: null,
      };
      this.errorU = null;
      if (isFormCorrect) return;
      const data = {
        id: this.sousTraitantToConfig.id,
        name: this.sousTraitantToConfig.name,
        num_siret: this.sousTraitantToConfig.num_siret,
        // num_rge: this.sousTraitantToConfig.num_rge,
      };

      await this.edit_sousTraitant(data)
        .then(() => {
          this.hideModal("ModelConfigSousTraitant");
          this.errorU = null;
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (this.erreurlist[key] && !this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorU = err;
          }
        });
    },

    handleStore() {
      this.$root.$emit("bv::show::modal", "sousTraitantModal");
    },

    pagination(paginate) {
      this.page = paginate;
      this.searchSousTraitant();
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;
      this.currententreprise = this.filtre_entreprise;

      if (this.getOnlineUser) {
        this.allsousTraitant({
          page: this.page,
          per_page: this.perPage,
        });
      }
    },
  },

  mounted() {
    this.setup(true);
    if (this.isSuperAdmin)
      this.all_entreprises({
        page: this.page,
        per_page: 1000,
        search: this.search,
      });
  },

  computed: {
    ...mapGetters([
      "getAllsousTraitant",
      "getLoadingsousTraitant",
      "getTotalRowsousTraitant",
      "getTabs",
      "getOnlineUser",
      "getAllentreprises",
      "getentrepriseLoading",
    ]),

    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
  },
};
</script>

<style lang="scss" scoped>
  .input-group .icon-balance {
    top: 8px;
  }

  .prodimg {
    padding: 8px;
    background: #465081;
    max-width: 64px;
    max-height: 64px;
  }

  .option {
    display: flex;
    align-items: center;

    .option__image {
      background-color: #465081;
      padding: 4px;
      max-height: 42px;
      max-width: 42px;
    }
  }

  .content p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }

  .w-100 {
    background-color: #f8f8f8;
    padding: 4px;
    overflow-x: auto;
  }

  body {
    background: #fafdff;
  }

  .actionModel {
    position: relative;
    padding: 15px 0 0;
    display: flex; /* Ajouté pour préciser le type de disposition */
    flex-flow: column;
    align-items: center;

    .messageError {
      color: #e4261b;
      font-size: 16px;
      background-color: #fff;
      border-color: #fff;
    }

    .block-spinner {
      display: flex;
      justify-content: center;
    }

    .spinner-border {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .detailClient {
    background-color: #fff;
    box-shadow: 1px 1px 24px #00000019;

    .clientTop {
      padding: 16px 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-bottom: 1px solid #707070;

      .prodimg {
        max-width: 185px;
        max-height: 231px;
      }
    }

    h4 {
      color: #28367a;
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }

    p {
      color: #515151;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .filtre {
    display: flex;
    align-items: center;

    .table-count,
    .productTrie {
      margin-right: 15px;
    }

    .productTrie {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      overflow: hidden;
      padding: 4px 8px;
      border-radius: 32px;
      background-color: #34c38f;
      color: #fff;

      .icons {
        cursor: pointer;
        width: 18px;
        height: 18px;
        margin-left: 4px;
      }
    }
  }

  .image-conteneur {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .block {
      cursor: pointer;
      width: 25%;
      margin-bottom: 16px;
      padding: 15px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1); /* Correction ici */
      }

      .image-content img {
        width: 100%;
      }

      p {
        margin: 0;
        text-align: center;
        color: #303030;
      }
    }
  }

  .galerieImage {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 20%;
      max-height: 100px;
    }
  }
</style>
